import React, { useEffect, useState } from 'react'
import { Button, Card, Modal, notification, Typography } from 'antd'
import idx from 'idx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { fetchHeroContentById, resetUpdateHeroStatus, resetHerosActionStatus, updateHeroContent, removeHeroById } from '~actions'
import { HeroBasicInfo, ResultModal } from '~components'
import { getCurrentUser } from '~context'
import { history } from '~history'
import { PageLayout, Spinner } from '~stories'
import { getFileName } from '~utils'
import { validateHero } from '~validators'

import styles from './EditHeroSection.module.css'

const { Text } = Typography

const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const EditHeroSection = ({
  deleteHeroById,
  fetchHeroContent,
  resetUpdateHeroStatus,
  route,
  deleteMessage,
  deleteStatus,
  heroContent,
  updateMessage,
  updateHeroContent,
  updateStatus
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState()
  const [heroContentId, setHeroContentId] = useState('')
  const [heroContentToEdit, setHeroContentToEdit] = useState({})
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [isDataDeleted, setIsDataDeleted] = useState(true)
  const [showHeroDeleteModal, setShowHeroDeleteModal] = useState(false)

  useEffect(() => {
    const heroContentId = idx(route, _ => _.match.params.id)
    if (heroContentId) {
      setIsDataLoaded(false)
      fetchHeroContent(heroContentId)
      setHeroContentId(heroContentId)
    }
  }, [route, fetchHeroContent])

  useEffect(() => {
    if (heroContent) {
      setHeroContentToEdit(heroContent)
      setIsDataLoaded(true)
    }
  }, [heroContent])

  useEffect(() => {
    if (updateStatus && updateMessage) {
      setIsDataLoaded(true)
    } else if (!updateStatus && updateMessage) {
      setIsDataLoaded(true)
      notification.error({ message: updateMessage })
      resetUpdateHeroStatus()
    }
  }, [updateStatus, updateMessage, resetUpdateHeroStatus])

  useEffect(() => {
    if (deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      notification.success({ message: deleteMessage })
      setTimeout(() => {
        history.push('/hero-modules')
      }, 1500)
    } else if (!deleteStatus && deleteMessage) {
      setIsDataLoaded(false)
      setIsDataDeleted(true)
      notification.error({ message: deleteMessage })
      resetHerosActionStatus()
    }
  }, [deleteStatus, deleteMessage])

  const handleDeleteHero = () => {
    setShowHeroDeleteModal(true)
  }

  const handleSubmit = () => {
    const { isValid, message } = validateHero(heroContentToEdit)
    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    setIsDataLoaded(false)
    const updatedHeroContent = {
      ...heroContentToEdit
    }
    updateHeroContent(heroContentId, updatedHeroContent, accessToken)
  }

  const keepEditingHero = (
    <Route key="editHeroContent" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateHeroStatus()
          history.push(`/hero-modules/${heroContentId}/edit`)
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const goToListHeroContentButton = (
    <Route key="listHeroContent" render={({ history }) => (
      <Button
        onClick={() => {
          resetUpdateHeroStatus()
          history.push('/hero-modules')
        }}
        type="primary"
      >
        Go to Hero Content List
      </Button>
    )} />
  )

  const handleModalClose = () => {
    resetUpdateHeroStatus()
  }

  const handleTitleChange = event => {
    const title = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      title
    })
  }

  const handleButtonLabelChange = event => {
    const buttonName = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      button_name: buttonName
    })
  }
  const handlePhoneNumberChange = event => {
    const phoneNumber = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      phone_number: phoneNumber
    })
  }
  const handleDescriptionChange = description => {
    setHeroContentToEdit((heroContentToEdit) => ({
      ...heroContentToEdit,
      description
    }))
  }
  const handleSubTitleChange = event => {
    const subTitle = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      sub_title: subTitle
    })
  }
  const handleImageUrlUpdate = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    const alt = imageUrl ? getFileName(imageUrl) : ''
    setHeroContentToEdit({
      ...heroContentToEdit,
      image: imageUrl ? {
        alt,
        url: imageUrl
      } : null
    })
  }
  const handleChatLabelChange = event => {
    const chatButton = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      chat_button: chatButton
    })
  }

  const handleFormIdChange = event => {
    const formId = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      formstack_id: formId
    })
  }

  const handleEmailChange = event => {
    const email = event.target.value
    setHeroContentToEdit({
      ...heroContentToEdit,
      email
    })
  }

  const handleDeleteHeroModalCancel = () => {
    setShowHeroDeleteModal(false)
  }

  const handleDeleteHeroAction = () => {
    const heroContentId = idx(route, _ => _.match.params.id)
    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }
    setIsDataDeleted(false)
    setIsDataLoaded(false)
    deleteHeroById(heroContentId, accessToken)
    setShowHeroDeleteModal(false)
  }

  const updateHeroContentButton = <Button className={styles.updateActionButton} onClick={handleSubmit} type='primary'>
    Update
  </Button>
  const deleteButton = <Button className={styles.deleteActionButton} loading={!isDataDeleted} onClick={handleDeleteHero} type='danger'>
  Delete
  </Button>

  return (
    <PageLayout extra={[updateHeroContentButton, deleteButton]} title='Update Hero Content'>
      <Card>
        <HeroBasicInfo
          handleButtonLabelChange={handleButtonLabelChange}
          handleChatLabelChange={handleChatLabelChange}
          handleContentChange={handleDescriptionChange}
          handleEmailChange={handleEmailChange}
          handleFormIdChange={handleFormIdChange}
          handleImageUrlUpdate={handleImageUrlUpdate}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handleSubTitleChange={handleSubTitleChange}
          handleTitleChange={handleTitleChange}
          hero={heroContentToEdit}
        />
      </Card>
      {heroContentToEdit && showHeroDeleteModal && <Modal
        onCancel={handleDeleteHeroModalCancel}
        onOk={handleDeleteHeroAction}
        title="Delete Hero"
        visible={showHeroDeleteModal}
      >
        <Text>
          This hero content will be removed from any linked pages. Are you sure you want to delete this Hero Content?
        </Text>
      </Modal>}
      <Spinner isLoading={!isDataLoaded}/>
      {
        updateStatus &&
        <ResultModal
          actions={[
            goToListHeroContentButton,
            keepEditingHero
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

EditHeroSection.propTypes = {
  deleteHeroById: PropTypes.func,
  deleteMessage: PropTypes.string,
  deleteStatus: PropTypes.bool,
  fetchHeroContent: PropTypes.func,
  heroContent: PropTypes.object,
  resetUpdateHeroStatus: PropTypes.func,
  route: PropTypes.object.isRequired,
  updateHeroContent: PropTypes.func,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.string
}

const mapStateToProps = ({ heroContents }) => ({
  heroContent: heroContents.heroContent,
  updateStatus: heroContents.success,
  deleteMessage: heroContents.deleteMessage,
  deleteStatus: heroContents.deleteStatus,
  updateMessage: heroContents.message
})

const mapDispatchToProps = {
  deleteHeroById: removeHeroById,
  fetchHeroContent: fetchHeroContentById,
  resetUpdateHeroStatus,
  updateHeroContent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditHeroSection)
