const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_TITLE: 'Provide valid title',
  INVALID_COLOR: 'Provide valid color code'
}

const isTitleValid = title => title.length > 0

const isColorObjectValid = (color) => {
  if (!color || typeof color !== 'object') {
    return false
  }

  if (!color.name || typeof color.name !== 'string' || !color.name.trim()) {
    return false
  }

  if (!color.value || typeof color.value !== 'string') {
    return false
  }

  return true
}

export const validateFlag = flag => {
  const { title, color } = flag

  if (!isTitleValid(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isColorObjectValid(color)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_COLOR }
  }

  return { isValid: true, message: '' }
}
