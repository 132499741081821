import React from 'react'
import { Typography } from 'antd'
import idx from 'idx'

import { ItemList } from '~components'
import { PageLayout } from '~stories'

import './Artists.modules.css'

const { Text } = Typography

const getArtistImage = (artist) => {
  const imageUrl = idx(artist, _ => _.images[0].url)
  return imageUrl && <img alt='Artist' height='100px' src={imageUrl} width='200px' />
}

const getArtistNameWithLink = artist => <Text>{String(artist.name).toUpperCase()}</Text>

const columnData = [
  {
    render: artist => getArtistImage(artist),
    title: 'Image',
    width: '15%'
  },
  {
    dataIndex: 'name',
    render: (text, artist) => getArtistNameWithLink(artist),
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    title: 'Name',
    width: '30%'
  },
  {
    dataIndex: 'url',
    title: 'Ticketmaster Url',
    width: '25%'
  },
  {
    dataIndex: 'id',
    title: 'TM Id',
    width: '30%'
  }
]

const Artists = () => {
  return (
    <PageLayout title="Artists">
      <div className="artists-layout-background">
        <ItemList columns={columnData} resource='artists' />
      </div>
    </PageLayout>
  )
}

export default Artists
