import React, { useState } from 'react'
import { Button, notification } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'

import { addHero, resetHerosActionStatus } from '~actions'
import { ResultModal, HeroBasicInfo } from '~components'
import { getCurrentUser } from '~context'
import { PageLayout } from '~stories'
import { getFileName } from '~utils'
import { validateHero } from '~validators'

const PAGE_TITLE = 'New Hero'
const NOTIFICATION_MESSAGES = {
  UNAUTHORIZED: 'You are not authorized to perform this action.'
}

const NewHero = ({
  addHero,
  newHeroId,
  updateMessage,
  updateStatus
}) => {
  const { access_token: accessToken, userDetails: { isContentEditor } } = getCurrentUser()
  const [hero, setHero] = useState({
    title: '',
    sub_title: '',
    description: '',
    button_name: '',
    phone_number: '',
    formstack_id: '',
    chat_button: '',
    email: '',
    image: null
  })

  const handleTitleChange = event => {
    const title = event.target.value
    setHero({
      ...hero,
      title
    })
  }

  const handleButtonLabelChange = event => {
    const buttonName = event.target.value
    setHero({
      ...hero,
      button_name: buttonName
    })
  }
  const handlePhoneNumberChange = event => {
    const phoneNumber = event.target.value
    setHero({
      ...hero,
      phone_number: phoneNumber
    })
  }

  const handleDescriptionChange = description => {
    setHero((hero) => ({
      ...hero,
      description
    }))
  }
  const handleSubTitleChange = event => {
    const subTitle = event.target.value
    setHero({
      ...hero,
      sub_title: subTitle
    })
  }
  const handleImageUrlUpdate = (thumbnailImageUrl, secureImageURL) => {
    const imageUrl = secureImageURL || thumbnailImageUrl
    const alt = getFileName(imageUrl)
    setHero({
      ...hero,
      image: {
        alt,
        url: imageUrl
      }
    })
  }
  const handleChatLabelChange = event => {
    const chatButton = event.target.value
    setHero({
      ...hero,
      chat_button: chatButton
    })
  }

  const handleFormIdChange = event => {
    const formId = event.target.value
    setHero({
      ...hero,
      formstack_id: formId
    })
  }

  const handleEmailChange = event => {
    const email = event.target.value
    setHero({
      ...hero,
      email
    })
  }
  const handleSubmit = () => {
    const { isValid, message } = validateHero(hero)

    if (!isValid) {
      notification.error({ message })
      return
    }

    if (!isContentEditor) {
      notification.error({ message: NOTIFICATION_MESSAGES.UNAUTHORIZED })
      return
    }

    addHero({
      ...hero
    }, accessToken)
  }

  const handleModalClose = () => {
    resetHerosActionStatus()
  }

  const addHerosButton = (
    <Button
      onClick={() => {
        resetHerosActionStatus()
        window.location.reload()
      }}
      type="primary"
    >
      Add Another
    </Button>
  )

  const goToListHerosButton = (
    <Route key="listHeros" render={({ history }) => (
      <Button
        onClick={() => {
          resetHerosActionStatus()
          history.push('/hero-modules')
          window.location.reload()
        }}
        type="primary"
      >
        Go to Hero Content List
      </Button>
    )} />
  )

  const editHeroContent = (
    <Route key="editHeroContent" render={({ history }) => (

      <Button
        onClick={() => {
          newHeroId && history.push(`/hero-modules/${newHeroId}/edit`)
          window.location.reload()
        }}
        type="primary"
      >
        Keep Editing
      </Button>
    )} />
  )

  const addHeroButton = <Button onClick={handleSubmit} type='primary'>
    Add
  </Button>

  return (
    <PageLayout extra={[addHeroButton]} title={PAGE_TITLE}>
      <div>
        <HeroBasicInfo
          handleButtonLabelChange={handleButtonLabelChange}
          handleChatLabelChange={handleChatLabelChange}
          handleContentChange={handleDescriptionChange}
          handleEmailChange={handleEmailChange}
          handleFormIdChange={handleFormIdChange}
          handleImageUrlUpdate={handleImageUrlUpdate}
          handlePhoneNumberChange={handlePhoneNumberChange}
          handleSubTitleChange={handleSubTitleChange}
          handleTitleChange={handleTitleChange}
          hero={hero}
        />
      </div>
      {
        updateStatus &&
        <ResultModal
          actions={[
            editHeroContent,
            goToListHerosButton,
            addHerosButton
          ]}
          handleCancel={handleModalClose}
          status="success"
          title={updateMessage}
        />
      }
    </PageLayout>
  )
}

const mapStateToProps = ({ heroContents }) => ({
  newHeroId: heroContents.newHeroId,
  updateMessage: heroContents.message,
  updateStatus: heroContents.success
})

const mapDispatchToProps = {
  addHero
}

NewHero.propTypes = {
  addHero: PropTypes.func,
  newHeroId: PropTypes.string,
  updateMessage: PropTypes.string,
  updateStatus: PropTypes.bool
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewHero)
