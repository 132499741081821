import React, { useRef } from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import SunEditor from 'suneditor-react'
import { Label } from '~stories'

import 'suneditor/dist/css/suneditor.min.css'

const { Item } = Form

const RichTextQuillEditor = ({ handleContentChange, maxCharCount = 200, enableCharCount = false, label, htmlString = '' }) => {
  const editorRef = useRef(null)

  const handleChange = (content) => {
    const cleanedContent = content.replace(/<br>$/, '')
    editorRef?.current?.setContents(cleanedContent)
    handleContentChange(cleanedContent)
  }

  return <Item>
    <Label value={label} />
    <SunEditor
      ref={editorRef}
      onChange={handleChange}
      setContents={htmlString}
      setOptions={{
        buttonList: [
          ['undo', 'redo'],
          ['formatBlock'],
          [
            'bold',
            'underline',
            'italic'
          ],
          ['link'],
          ['list'],
          ['table', 'horizontalRule', 'link', 'image'],
          ['fullScreen', 'codeView']
        ],
        minHeight: '300px',
        ...(
          enableCharCount && {
            charCounter: true,
            charCounterType: 'char',
            charCounterLabel: 'Characters:',
            maxCharCount
          }
        )
      }}
    />
  </Item>
}

RichTextQuillEditor.propTypes = {
  enableCharCount: PropTypes.bool,
  handleContentChange: PropTypes.func.isRequired,
  htmlString: PropTypes.sgtring,
  label: PropTypes.string,
  maxCharCount: PropTypes.number
}

export default RichTextQuillEditor
