import {
  AddOrganisation,
  AddPartnerVenue,
  AddPromoCode,
  AddSeries,
  Admin,
  ArtistsList,
  CFValidation,
  Contents,
  CopyPriceTemplate,
  CopyPromoCode,
  Dashboard,
  DynamicPriceTemplatesList,
  EditEvent,
  EditFlag,
  EditNavigations,
  EditOptInModal,
  EditOrganisation,
  EditPriceTemplate,
  EditPromoCode,
  EditSeries,
  EditSponsor,
  EventsList,
  FixedPriceTemplatesList,
  FlagsList,
  MarqueeImageUpload,
  MsgPromoCodesList,
  NavigationsList,
  NewFlag,
  NewNavigation,
  NewOptInModal,
  NewPriceTemplate,
  NewSponsor,
  OptInModals,
  OrganizationsList,
  PublishingPreferences,
  RockettesPromoCodesList,
  SeriesList,
  SponsorsList,
  TMImportLogsList,
  Uploader,
  VenuesEdit,
  VenuesList
} from './'

export const RoutesList = [
  {
    path: '/',
    redirectTo: '/dashboard'
  },
  {
    path: '/admin',
    component: Admin
  },
  {
    path: '/admin/content',
    component: Contents
  },
  {
    path: '/admin/sphere/uploader',
    component: Uploader
  },
  {
    component: ArtistsList,
    path: '/artists'
  },
  {
    component: AddPromoCode,
    path: '/promo_codes/:domain/new'
  },
  {
    component: AddSeries,
    path: '/series/new'
  },
  {
    component: Dashboard,
    path: '/dashboard'
  },
  {
    component: DynamicPriceTemplatesList,
    path: '/price_templates/dynamic'
  },
  {
    component: EditPriceTemplate,
    path: '/price_templates/:id/edit'
  },
  {
    component: CopyPriceTemplate,
    path: '/price_templates/:id/copy'
  },
  {
    component: CopyPromoCode,
    path: '/promo_codes/:id/copy'
  },
  {
    component: EditEvent,
    path: '/events/:id/edit'
  },
  {
    component: EventsList,
    path: '/events'
  },
  {
    component: EditPromoCode,
    path: '/promo_codes/:id/edit'
  },
  {
    component: EditOrganisation,
    path: '/organizations/:id/edit'
  },
  {
    component: FixedPriceTemplatesList,
    path: '/price_templates/fixed'
  },
  {
    component: MsgPromoCodesList,
    path: '/promo_codes/msg'
  },
  {
    component: NewPriceTemplate,
    path: '/price_templates/:type/new'
  },
  {
    component: OrganizationsList,
    path: '/organizations'
  },
  {
    component: AddOrganisation,
    path: '/organizations/new'
  },
  {
    component: PublishingPreferences,
    path: '/publishing_preferences'
  },
  {
    component: RockettesPromoCodesList,
    path: '/promo_codes/rockettes'
  },
  {
    component: SeriesList,
    path: '/series'
  },
  {
    component: NewNavigation,
    path: '/navigations/new'
  },
  {
    component: EditNavigations,
    path: '/navigations/:id'
  },
  {
    component: NavigationsList,
    path: '/navigations'
  },
  {
    component: NewOptInModal,
    path: '/opt-in-modal/new'
  },
  {
    component: EditOptInModal,
    path: '/opt-in-modal/:id/edit'
  },
  {
    component: OptInModals,
    path: '/opt-in-modal'
  },
  {
    component: EditSeries,
    path: '/series/:id/edit'
  },
  {
    component: SponsorsList,
    path: '/sponsors'
  },
  {
    component: NewSponsor,
    path: '/sponsors/new'
  },
  {
    component: EditSponsor,
    path: '/sponsors/:id/edit'
  },
  {
    component: FlagsList,
    path: '/flags'
  },
  {
    component: NewFlag,
    path: '/flags/new'
  },
  {
    component: EditFlag,
    path: '/flags/:id/edit'
  },
  {
    component: MarqueeImageUpload,
    path: '/mq-img-upload'
  },
  {
    component: TMImportLogsList,
    path: '/tm_import_logs'
  },
  {
    component: AddPartnerVenue,
    path: '/venues/partner/new'
  },
  {
    component: VenuesEdit,
    path: '/venues/:id/edit'
  },
  {
    component: VenuesList,
    path: '/venues'
  },
  {
    component: CFValidation,
    path: '/cf-invalidation'
  }
]
