const VALIDATION_ERRORS = {
  INVALID_DESCRIPTION: 'Provide valid description.',
  INVALID_SUBTITLE: 'Provide valid sub_title.',
  INVALID_ICON_URL: 'Provide valid icon url.',
  INVALID_BUTTON_NAME: 'Provide valid button_name.',
  INVALID_PHONE_NUMBER: 'Provide valid phone number.',
  INVALID_EMAIL: 'Provide valid email.',
  INVALID_IMAGE: 'Provide valid image.',
  INVALID_CHAT_BUTTON: 'Provide valid chat button.',
  INVALID_FORM: 'Provide valid form id.',
  INVALID_TITLE: 'Provide valid title',
  INVALID_EMAIL_FORMAT: 'Provide valid email address'
}

const isValidTitle = title => title.length > 0

const isValidImageObject = (image) => {
  if (!image || typeof image !== 'object') {
    return false
  }

  if (!image.url || typeof image.url !== 'string' || !image.url.trim()) {
    return false
  }

  if (!image.alt || typeof image.alt !== 'string') {
    return false
  }

  return true
}

const validateEmail = (email) => {
  const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegEx.test(email)
}

export const validateHero = flag => {
  const { title, sub_title: subTitle, description, button_name: buttonName, phone_number: phoneNumber, chat_button: chatButton, email, formstack_id: formId, image } = flag

  if (!isValidTitle(title)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_TITLE }
  }

  if (!isValidTitle(description)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_DESCRIPTION }
  }

  if (!isValidTitle(subTitle)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_SUBTITLE }
  }

  if (!isValidTitle(buttonName)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_BUTTON_NAME }
  }

  if (!isValidTitle(phoneNumber)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_PHONE_NUMBER }
  }

  if (!isValidTitle(chatButton)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_CHAT_BUTTON }
  }

  if (!isValidTitle(formId)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_FORM }
  }

  if (!isValidTitle(email)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EMAIL }
  }

  if (!isValidImageObject(image)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_IMAGE }
  }

  if (!validateEmail(email)) {
    return { isValid: false, message: VALIDATION_ERRORS.INVALID_EMAIL_FORMAT }
  }
  return { isValid: true, message: '' }
}
