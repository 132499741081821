import { fetchPresignedUrl, uploadFileToS3WithPresignedUrl } from '~actions'
import { SPHERE_PATH_LOCATION } from '~constants'

const GENERIC_ERROR_MESSAGE = 'Unable to upload file(s), please reach out to the od-sphere developement team.'
const { REACT_APP_STAGE, REACT_APP_SPHERE_CDN_URL, REACT_APP_SPHERE_BUCKET_NAME } = process.env

const path = `${SPHERE_PATH_LOCATION[REACT_APP_STAGE]}`

export const createS3URLs = async ({ fileList, pageName }) => {
  const uploadedFiles = []

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i]

    try {
      const key = `${path}/${pageName}/${file.name}`
      const response = await fetchPresignedUrl({
        fileName: key,
        fileType: file.type,
        bucket: REACT_APP_SPHERE_BUCKET_NAME
      })
      const { presignedPutUrl } = response
      const res = await uploadFileToS3WithPresignedUrl(presignedPutUrl, file)

      console.log(res)

      uploadedFiles.push(`${REACT_APP_SPHERE_CDN_URL}/${key}`)
    } catch (err) {
      console.error(err)
      throw Error(GENERIC_ERROR_MESSAGE)
    }
  }

  console.log(uploadedFiles)

  return uploadedFiles
}
