import 'isomorphic-fetch'

import { heroEndpoint } from '~apiConfig'
import { getApiResponseInJSON, getIdToken } from '~utils'
import {
  ADD_NEW_HERO,
  FETCH_HERO_CONTENTS,
  HERO_UPDATE_STATUS,
  REMOVE_HERO_BY_ID,
  STORE_HERO_CONTENTS_TO_EDIT
} from '../actionTypes'

export const resetHerosActionStatus = () => async dispatch => {
  dispatch({
    type: ADD_NEW_HERO,
    payload: {
      error: false,
      message: '',
      success: false
    }
  })
}

export const resetUpdateHeroStatus = () => async dispatch => {
  dispatch({
    type: HERO_UPDATE_STATUS,
    payload: {
      message: '',
      success: false
    }
  })
}

export const addHero = (hero, accessToken) => async dispatch => {
  try {
    const { method, url } = heroEndpoint.add
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify({ hero }),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { addedHero: { _id: newHeroId }, message, success } = response
        dispatch({
          type: ADD_NEW_HERO,
          payload: { newHeroId, message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}

export const removeHeroById = (heroId, accessToken) => async dispatch => {
  const { method, url } = heroEndpoint.delete(heroId)
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    method,
    headers: {
      Authorization: `Bearer ${idToken}`,
      AccessToken: accessToken
    }
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => ({
      message: error.message,
      success: false
    }))
  const { message: deleteMessage, success: deleteStatus } = apiResponse

  dispatch({
    type: REMOVE_HERO_BY_ID,
    payload: {
      deleteMessage,
      deleteStatus
    }
  })
}

export const fetchHeroContentsList = params => async dispatch => {
  const { method, url } = heroEndpoint.list
  const idToken = getIdToken()
  const apiResponse = await fetch(url, {
    body: JSON.stringify(params),
    headers: {
      Authorization: `Bearer ${idToken}`
    },
    method
  })
    .then(async response => await getApiResponseInJSON(response))
    .catch(error => console.error(error))
  const { heroModules: rows, heroModulesCount: rowsCount } = apiResponse
  dispatch({
    type: FETCH_HERO_CONTENTS,
    payload: {
      rows,
      rowsCount,
      params
    }
  })
}

export const fetchHeroContentById = heroObjectId => async dispatch => {
  try {
    const { method, url } = heroEndpoint.edit(heroObjectId)
    const idToken = getIdToken()
    const apiResponse = await fetch(url, {
      headers: {
        Authorization: `Bearer ${idToken}`
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .catch(error => console.error(error))
    dispatch({
      type: STORE_HERO_CONTENTS_TO_EDIT,
      payload: {
        ...apiResponse
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const updateHeroContent = (heroObjectId, flag, accessToken) => async dispatch => {
  try {
    const { method, url } = heroEndpoint.update(heroObjectId)
    const idToken = getIdToken()
    await fetch(url, {
      body: JSON.stringify(flag),
      headers: {
        Authorization: `Bearer ${idToken}`,
        AccessToken: accessToken
      },
      method: method
    })
      .then(async response => await getApiResponseInJSON(response))
      .then(response => {
        const { message, success } = response
        dispatch({
          type: HERO_UPDATE_STATUS,
          payload: { message, success }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  } catch (error) {
    console.log(error)
  }
}
