import {
  ADD_NEW_HERO,
  FETCH_HERO_CONTENTS,
  HERO_UPDATE_STATUS,
  REMOVE_HERO_BY_ID,
  STORE_HERO_CONTENTS_TO_EDIT
} from '../actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NEW_HERO:
      return {
        ...state,
        ...action.payload
      }
    case FETCH_HERO_CONTENTS:
      return {
        ...state,
        ...action.payload
      }
    case STORE_HERO_CONTENTS_TO_EDIT:
      return {
        ...state,
        ...action.payload
      }
    case HERO_UPDATE_STATUS:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_HERO_BY_ID:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
