import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { SPHERE_UPLOAD_ALLOWED_FILE_TYPES } from '~constants'

import './DropArea.modules.css'

const DropAreaComponent = ({ onError, onEventSuccess }) => {
  const [uploadedFiles, setUploadedFiles] = useState(null)
  const inputRef = useRef(null)

  const onDragoverHandler = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onDrop = async (event) => {
    event.preventDefault()

    // Confirm the extensions can only be, .png, .jpg or .jpeg
    const { dataTransfer: { files } } = event
    validateFiles(files)
  }

  const onDropAreaClickHandler = () => {
    inputRef.current.click()
  }

  const onFileClickHandler = (event) => {
    const { files } = event.target
    validateFiles(files)
  }

  const validateFiles = (files) => {
    const invalidFiles = []
    const validFiles = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      if (SPHERE_UPLOAD_ALLOWED_FILE_TYPES.indexOf(file.type) !== -1) {
        validFiles.push(file)
      } else {
        invalidFiles.push(file.name)
      }
    }

    if (invalidFiles.length > 0) {
      onError({ msg: `Invalid files: ${invalidFiles.join(', ')}` })
    } else {
      setUploadedFiles(validFiles)
      onEventSuccess(validFiles)
    }
  }

  return (
    <React.Fragment>
      <div className="drop-box"
        onClick={onDropAreaClickHandler}
        onDragOver={onDragoverHandler}
        onDrop={(evt) => onDrop(evt).catch(err => console.error(err))}>
        <div>
          Drag and drop file(s) to upload and create S3 URLs!
          <br />
          <em>or</em> click to choose a file/files.
        </div>
        <input ref={inputRef} multiple={true} name="uploader" onChange={onFileClickHandler} type="file" />
      </div>

      {uploadedFiles ? <div>{
        Array.from(uploadedFiles).map((file, idx) => <span key={idx}>{file.name}, </span>)} have been uploaded, please continue filling out the form to get S3 URLs.</div>
        : null }
    </React.Fragment>
  )
}

DropAreaComponent.propTypes = {
  onError: PropTypes.func,
  onEventSuccess: PropTypes.func
}

export default DropAreaComponent
