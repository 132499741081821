import React, { useRef, useState } from 'react'
import { CopyOutlined } from '@ant-design/icons'

import { DropArea } from '~components'
import { SPHERE_UPLOAD_GENERIC_ERROR_MSG } from '~constants'

import { createS3URLs } from '~services'
import { PageLayout } from '~stories'

import './Uploader.modules.css'

const PAGE_TITLE = 'Sphere Image Uploader'

const UploaderComponent = () => {
  const [uploadedFiles, setUploadedFiles] = useState(null)
  const [s3Files, setS3Files] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const fileListRef = useRef(null)

  const onFileListClick = () => {
    const contentToCopy = []

    const { current: { children } } = fileListRef
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      contentToCopy.push(child.textContent)
    }

    navigator.clipboard.writeText(contentToCopy.toString()).catch(err => console.error(err))
  }

  const handleDropAreaError = (error) => {
    if (error.msg) {
      setErrorMessage(error.msg)
    } else {
      setErrorMessage(SPHERE_UPLOAD_GENERIC_ERROR_MSG)
    }
  }

  const handleUploadedFiles = (fileList) => {
    setS3Files(null)
    setUploadedFiles(fileList)
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault()
    setErrorMessage(null)

    const { elements } = event.target
    const pageName = Array.from(elements).find(el => el.name === 'file-dir')

    if (!pageName.value || (!uploadedFiles || uploadedFiles.length === 0)) {
      setErrorMessage('Please fill out all fields and upload files.')
    } else {
      const result = await createS3URLs({ fileList: uploadedFiles, pageName: pageName.value })
      setS3Files(result)
    }
  }

  return (
    <PageLayout title={PAGE_TITLE}>

      { errorMessage ? <div className="error-message">{errorMessage}</div> : null }

      <form noValidate onSubmit={(event) => onSubmitHandler(event).catch(err => setErrorMessage(err.msg))}>

        <div className="form-row">
          <label>Event/Page Name</label>
          <input name="file-dir" type="text" />
        </div>

        <DropArea onError={handleDropAreaError} onEventSuccess={handleUploadedFiles} />

        <div className="form-row">
          <button name="submit-btn" type="submit">Upload</button>
        </div>

      </form>

      {s3Files && s3Files.length > 0 ? <React.Fragment>
        <hr />
        <div className="file-list" onClick={onFileListClick}>
          <span><CopyOutlined /></span>
          <ul ref={fileListRef}>
            {s3Files.map((file, idx) => {
              return <li key={idx}>{file}</li>
            })}
          </ul>
        </div>
      </React.Fragment> : null}

    </PageLayout>
  )
}

export default UploaderComponent
